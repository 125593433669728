"use client";

import { useState } from "react";
import ObjectViewer from "components/collection/object-viewer";
import { lofiArtworkObjectModel } from "helpers/collection";
import type { GraphQLObject, IGraphQLObjectPackageObject } from "@metmuseum/types/src/graphql-object";
import styles from "./object-viewer-button.module.scss";
import ImageIcon from "assets/icons/image.svg";
export const ObjectViewerButton = ({
  object,
  text
}: IProps) => {
  const [showObjectViewer, setShowObjectViewer] = useState(false);
  const formattedObject = lofiArtworkObjectModel(object);
  const handleObjectClick = e => {
    e.preventDefault();
    setShowObjectViewer(true);
  };
  const handleCloseClick = () => setShowObjectViewer(false);
  return <span data-sentry-component="ObjectViewerButton" data-sentry-source-file="index.tsx">
			<button title={`Preview of "${formattedObject.title}"`} className={styles.button} onClick={handleObjectClick}>
				<ImageIcon className={styles.icon} data-sentry-element="ImageIcon" data-sentry-source-file="index.tsx" />
				<span className={styles.text}>{text}</span>
			</button>
			{showObjectViewer && <ObjectViewer objects={[formattedObject]} initialActiveObjectIndex={0} handleCloseClick={handleCloseClick} />}
		</span>;
};
interface IProps {
  text: string;
  object: IGraphQLObjectPackageObject | GraphQLObject;
}